.ConfirmationModal {
  .ant-modal-content {
    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      color: @primary-color !important;

      .anticon {
        font-size: 1.8rem;
      }
    }
    .title {
      color: @primary-color !important;
    }

    .message {
      margin-bottom: 40px !important;
    }

    .actions {
      .positive,
      .negative {
        min-width: 182px !important;
      }

      .negative {
        background-color: @white !important;
        border-color: transparent !important;
      }
    }
  }
}
