h1,
h2,
h3,
h4,
h5 {
  &.light,
  &.ant-typography.light {
    font-weight: 300;
  }
}

div.ant-typography,
span.ant-typography {
  strong {
    font-weight: bold;
  }

  &.light {
    font-weight: 300;
  }

  &.small {
    font-size: 0.875rem;
  }

  &.big {
    font-size: 1.5rem;
  }
}
