@import (reference) '~antd/dist/antd.less';

.action-form {
  .ant-form-item {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 16px;

    &.checkbox {
      .ant-checkbox-wrapper {
        color: @black-5;
        background-color: @black-2;
        padding: 10px;
        width: 100%;
        min-height: 55px;
        display: flex;
        align-items: center;
      }
    }
  }

  .ant-form-item-label > label {
    color: @black-2;
    font-size: 0.857rem;

    &.ant-form-item-required::before {
      color: @secondary-color;
    }
  }

  .ant-input {
    font-size: 1.143rem;
    color: @black-1;
    background-color: @input-background;
    border: 1px solid @input-border;
    border-radius: 4px;
    caret-color: @primary-color;

    &:focus,
    &:active {
      border-color: @design-primary-60;
    }
  }

  .ant-form-item-has-error .ant-input {
    background-color: @input-background;
  }

  .submit-container {
    @media screen and (min-width: 768px) {
      padding-top: 15px;
    }

    .ant-btn {
      display: block;
      width: 100%;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
      height: 64px;
    }
  }
}

.ant-form-item {
  &:not(.ant-form-item-has-error) {
    .ant-input {
      border: 1px solid #d8d8d9 !important;
    }
  }
}

.ant-input {
  border-radius: 4px !important;
}
