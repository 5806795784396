.ant-btn {
  font-size: 1rem;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px solid @primary-color !important;

  &.ant-btn-text {
    border-color: transparent !important;
    color: @primary-color;

    &:hover,
    &:focus {
      color: @primary-color;
      background: inherit;
    }
  }

  &.ant-btn-link {
    color: @primary-color;
  }

  &:disabled {
    border-color: transparent !important;
    :hover {
      border-color: transparent !important;
    }
  }
}
