h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h1.ant-typography.light,
h2.ant-typography.light,
h3.ant-typography.light,
h4.ant-typography.light,
h5.ant-typography.light {
  font-weight: 300;
}
div.ant-typography strong,
span.ant-typography strong {
  font-weight: bold;
}
div.ant-typography.light,
span.ant-typography.light {
  font-weight: 300;
}
div.ant-typography.small,
span.ant-typography.small {
  font-size: 0.875rem;
}
div.ant-typography.big,
span.ant-typography.big {
  font-size: 1.5rem;
}
.action-form .ant-form-item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 16px;
}
.action-form .ant-form-item.checkbox .ant-checkbox-wrapper {
  color: #f4f5f5;
  background-color: #707372;
  padding: 10px;
  width: 100%;
  min-height: 55px;
  display: flex;
  align-items: center;
}
.action-form .ant-form-item-label > label {
  color: #707372;
  font-size: 0.857rem;
}
.action-form .ant-form-item-label > label.ant-form-item-required::before {
  color: #355b57;
}
.action-form .ant-input {
  font-size: 1.143rem;
  color: #3c393c;
  background-color: #f4f5f6;
  border: 1px solid #b2b2b3;
  border-radius: 4px;
  caret-color: #b19f71;
}
.action-form .ant-input:focus,
.action-form .ant-input:active {
  border-color: #b19f71;
}
.action-form .ant-form-item-has-error .ant-input {
  background-color: #f4f5f6;
}
@media screen and (min-width: 768px) {
  .action-form .submit-container {
    padding-top: 15px;
  }
}
.action-form .submit-container .ant-btn {
  display: block;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  height: 64px;
}
.ant-form-item:not(.ant-form-item-has-error) .ant-input {
  border: 1px solid #d8d8d9 !important;
}
.ant-input {
  border-radius: 4px !important;
}
.ant-btn {
  font-size: 1rem;
  border-radius: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 1px solid #b19f71 !important;
}
.ant-btn.ant-btn-text {
  border-color: transparent !important;
  color: #b19f71;
}
.ant-btn.ant-btn-text:hover,
.ant-btn.ant-btn-text:focus {
  color: #b19f71;
  background: inherit;
}
.ant-btn.ant-btn-link {
  color: #b19f71;
}
.ant-btn:disabled {
  border-color: transparent !important;
}
.ant-btn:disabled :hover {
  border-color: transparent !important;
}
.ConfirmationModal .ant-modal-content .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b19f71 !important;
}
.ConfirmationModal .ant-modal-content .ant-modal-close-x .anticon {
  font-size: 1.8rem;
}
.ConfirmationModal .ant-modal-content .title {
  color: #b19f71 !important;
}
.ConfirmationModal .ant-modal-content .message {
  margin-bottom: 40px !important;
}
.ConfirmationModal .ant-modal-content .actions .positive,
.ConfirmationModal .ant-modal-content .actions .negative {
  min-width: 182px !important;
}
.ConfirmationModal .ant-modal-content .actions .negative {
  background-color: #fff !important;
  border-color: transparent !important;
}
.color-primary {
  color: #b19f71;
}
.color-secondary {
  color: #355b57;
}
.text-align-center {
  display: block;
  text-align: center;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 1em;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1em !important;
}
.d-flex {
  display: flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.flex-direction-column {
  flex-direction: column;
}
.no-wrap {
  flex-wrap: nowrap;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative !important;
}
.cursor-pointer {
  cursor: pointer;
}
.ConfirmationModal .ant-modal-content {
  background-color: #fff;
}
.wysiwyg-text p {
  margin-bottom: 0;
}
#root {
  overflow-x: hidden;
}
