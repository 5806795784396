@import './headings.less';
@import './forms.less';
@import './buttons.less';
@import './modals.less';

.color-primary {
  color: @primary-color;
}

.color-secondary {
  color: @secondary-color;
}

.text-align-center {
  display: block;
  text-align: center;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 1em !important;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 1em;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1em !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 1em !important;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.flex-direction-column {
  flex-direction: column;
}

.no-wrap {
  flex-wrap: nowrap;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative !important;
}

.cursor-pointer {
  cursor: pointer;
}

.ConfirmationModal {
  .ant-modal-content {
    background-color: @white;
  }
}

.wysiwyg-text {
  p {
    margin-bottom: 0;
  }
}
