/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 9, 2021 */



@font-face {
    font-family: 'Open Sans';
    src: url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-bolditalic-webfont.woff2') format('woff2'),
         url('opensans-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-extrabold-webfont.woff2') format('woff2'),
         url('opensans-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-extrabolditalic-webfont.woff2') format('woff2'),
         url('opensans-extrabolditalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-italic-webfont.woff2') format('woff2'),
         url('opensans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-lightitalic-webfont.woff2') format('woff2'),
         url('opensans-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-semibold-webfont.woff2') format('woff2'),
         url('opensans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'Open Sans';
    src: url('opensans-semibolditalic-webfont.woff2') format('woff2'),
         url('opensans-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}